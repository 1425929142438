var params = {'2':[
  ['0','R'],  // 0 - product R
  ['R','P','C','O','E','PR'],  // 1 - shape
  ['C','D','UD','CD','LD'],  // 2 - color or user diamond or not
  ['0.25','0.33','0.5','0.75','1','1.25','1.5','2','2.5','C2','C3','3'],  //  3 - Center Size
  ['SA','EM','TA','RU','YS','PS'],  // 4 - Center stone Color
  ['D','BDI','SA','RU','EM','TA'],  // 5 - Head side color
  ['I','VS','SI'],  // 6 - Diamond Quality 
  ['S1','S2', 'B1', 'P1', 'P2','SS1', 'SS2', 'H1', 'H2','C1','T1TSPR', 'T1TSR', 'T1TSTR', 'T1TSO', 'T1TSP','TH2TSPR', 'TH2TSR', 'TH2TSTR', 'TH2TSO', 'TH2TSP'],  //  7 - Head
  ['1','2','3','4','5','6','7','8','10','11','12','14','15','16','17','18'],  // 8 - Shank
  ['0','R','P'],  // 9 - pkb
  ['D','BDI','SA','RU','EM'],  // 10 - pkb color
  ['0','CHR','UP','BD','PR','PV'],  // 11 - side stones
  ['1','2'],  // 12 - side length
  ['D','BDI','SA','BDID','SAD','RU','EM','RUD','EMD'],  // 13 - side color
  ['0','1','2','3'],  // 14 - en   
  ['10','14','18','PT'],  // 15 - ring metal  
  ['W','Y','R'],  // 16 - head metal color
  ['W','Y','R'],  // 17 - shank metal color
  ['1','2'],  // 18 - en length
  ['0','4','4.5','5','5.5','6','6.5','7','7.5','8','8.5','9'],  // 19 - ring size
  ['1','2'],  // 20 - Engr font Ring
  ['J','K','Z','A','E','T','1','2','3','4','5'],  // 21 - Store 1-ko 2-zo  3-people
  ['0','S','C','S2','C2'],  // 22 - band type
  ['0','CHR','UP','BD','PR','PV'],  // 23 - band side setting
  ['D','BDI','SA','BDID','SAD','RU','EM','RUD','EMD'],  // 24 - band side color
  ['1','2','3'],  // 25 - band side length  
  ['10','14','18','PT'],  // 26 - band metal  
  ['W','Y','R'],  // 27 - band metal color
  ['0','4','4.5','5','5.5','6','6.5','7','7.5','8','8.5','9' ],  // 28 - band ring size
  ['0','1','2','3'],  // 29 - band en              
  ['1','2','3'],  // 30 - band en length
  ['1','2'],  // 31 - Engr font Band 1
  ['1','2'],  // 32 - Engr font Band 2
   ]}
// PARAMS
// s - size (thumb,500,2000) default 500
// v - view (0,1,2,3) default = 0
// e - engraving (for r,b1,b2) for add to cart
// u - user diamond for add to cart(6 params)


var TOTAL_PARAM =33
var ring_list=[0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21];
var band_list=[22,23,24,25,26,27,28,29,30,31,32];

var ring_val = [];
var band_val = [];
var ring_val2 = [];
var band_val2 = [];
var version = '2';
var p = params[version];
var base = 35;


var alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ123456789';

function init() {
  var i;
  for (i in ring_list) {
    ring_val.push(p[ring_list[i]].length);
  }
  for (i in band_list)
    band_val.push(p[band_list[i]].length);
    
  var n = 1;
  for (i = 0; i < ring_val.length; i++) {
    n = n * ring_val[i];
    ring_val2.push(n);
  }
  n = 1;
  for (i = 0; i < band_val.length; i++) {
    n = n * band_val[i];
    band_val2.push(n);
  }
  // alert(ring_val)
  // alert(band_val)
  // alert(ring_val2)
  // alert(band_val2)
}
function param2skupart(in1, max_val, max_val2, base) {
  var sku = '';
  //var num = 0;
  var dec = 0;
 // alert(in1)
 // alert(max_val)
 var mul;
 var i;
  for (i = 0; i < max_val.length; i++) {
    if (i === 0)
      mul = 1;
    else
      mul = max_val2[i - 1];
    dec += in1[i] * mul;
  }
  // alert('decX'+String(dec))
  var sk = DecimalToBase(dec, base);
  sku = '';
  for (i = 0; i < sk.length; i++)
    sku = alphabet[sk[i]] + sku;
  return sku;
}
export function param2sku(in1,num=0) {
  var in2 = in1 //.split('-');
  console.log('p2sku',in1)
  //23+13 >20
  var new20 = '';
  //if (in2[23] !== 'B') 
  new20 += 'R';
  if (in2[22] !== '0') new20 += 'B';
  if (new20 === '') new20 = 'R';
  //in2[20] = new20;
  in2[28] = in2[19]; //bs=rs
  if (in2[7] === 'P1' || in2[7] === 'P2')
    in2[16] = 'W';
  //if (in2[23] === '0') {in2[24] = '0';in2[16] = '0';}
  //if (in2[8] === '0') {in2[9] = '0';in2[10] = '0';}
  //if (in2[11] === '0') {in2[12] = '0'}
  //if (in2[27] === '0') {in2[28] = '0'}
  

  var in3 = [];
  var in_ring = [];
  var in_band = [];
  //var j = 0;
  var i;
  var error = '';
  var res;
  if (in2.length < TOTAL_PARAM)
    error += 'param count in less than ' + String(TOTAL_PARAM);
  for (i = 0; i < TOTAL_PARAM; i++) {
    res = p[i].indexOf(in2[i]);
    if (res !== -1)
      in3.push(res);
    else {
      in3.push(0);
      error += 'error: ' + String(in2[i]) + ' not in index array ' + String(i) + ';';
      //console.log('ERROR SKU',in2,'not in','index',i,'originalsku',in1)
    }
  }
  // alert (in3)
  for (i = 0; i < ring_list.length; i++)
    in_ring.push(in3[ring_list[i]]);
  for (i = 0; i < band_list.length; i++)
    in_band.push(in3[band_list[i]]);
  var sku = version + param2skupart(in_ring, ring_val, ring_val2, base) + '0' + param2skupart(in_band, band_val, band_val2, base);
  // alert(sku)
  //console.log('return p2sku',sku, error)
  //console.log('param2sku',in1,in_ring,sku)
  
  return [sku, error];
}



 function sku2param(in1) {
  var resarray = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
  var in2 = in1.substring(1);
  var in3 = in2.split('0');
  var ring = sku2parampart(in3[0], ring_val2);
  //console.log('sku2param',in1,ring)
  if (in3.length===1)
    in3[1]=in3[0]
  var band = sku2parampart(in3[1], band_val2);
  var i;
  for (i = 0; i < ring.length; i++)
    resarray[ring_list[i]] = ring[i];
  for (i = 0; i < band.length; i++)
    resarray[band_list[i]] = band[i];
  if (resarray[2] === 3 || resarray[2] === 3)
    resarray[18] = 0;
  
  var res = [];
  for (i = 0; i < resarray.length; i++) {
    //if (res !== '') res += '-';
    res.push( p[i][resarray[i]]);
  }
  return res;
}

function sku2parampart(in2, max_temp) {
  var in1 = [];
  var pos;
  var i;
  for (i = 0; i < in2.length; i++) {
    pos = alphabet.indexOf(in2[i]);
    in1.push(pos);
  }
  var dec = 0;
  var param = [];
  // alert(in1);
  for (i = 0; i < in1.length; i++)
    dec += in1[i] * Math.pow(base, in1.length - i - 1);
  var maxlen = max_temp.length;
  for (i = 0; i < maxlen; i++) {
    if (i === maxlen - 1)
      param.unshift(Math.floor(dec));
    else
      param.unshift(Math.floor(dec / max_temp[maxlen - i - 2]));
    dec = Number(dec % max_temp[maxlen - i - 2]);
  	// alert(dec)
  	// alert(param)
  }
  return param;
}

function __DecimalToBaseRecur__(array, decimal, base) {
  array.push(decimal % base);
  // alert(decimal% base)
  var div = Math.floor(decimal / base);
  if (div === 0)
    return;
  __DecimalToBaseRecur__(array, div, base);
}

function DecimalToBase(decimal, base) {
  var array = [];
  __DecimalToBaseRecur__(array, decimal, base);
  return array;
}
init();
export function sku4band (sku) {
  let res= sku2param(sku)
  //res[20]='B'
  res[0]='0'
  let res2 =param2sku(res,4)[0]
  ////console.log('resB',sku,'>',res2,res)
  return res2
}
export function sku4ring (sku) {
  let res= sku2param(sku)
  res[0]='R'
  res[22]='0'
  let res2 =param2sku(res,41)[0]
  ////console.log('res',sku,'>',res2)
  return res2
}
export function sku2array(in1) {
  
  let res= sku2param(in1)
  //console.log('sku2array',in1,res)
  let res2={}
     
     if (res[11]==='PR' )
      res[14]='0'
     if (res[23]==='PR')
      res[29]='0'
     res2['ce'] =  res[2]
     res2['cec'] =  res[4]
     res2['ds'] =  res[1]
     if (res[31]==='2' )
      res2['ds'] =  'M'
     res2['dctw'] =  res[3]
     res2['head'] =  res[7]
     res2['headc'] =    res[5]
     res2['pkb'] =    res[9]
     res2['sh'] =    res[8]
     res2['rset'] =  res[11]
     res2['rsetc'] =  res[13]
     res2['rsetl'] =  res[12]
     res2['ren'] =  res[14]
     res2['renl'] =  res[18]
     res2['rmet'] =  res[15]
     res2['pkbc'] =  "D"
     res2['hmetc'] =  res[16]
     res2['smetc'] = res[17]
     res2['band'] =  res[22] 
     res2['bset'] =  res[23] 
     res2['bsetc'] =  res[24] 
     res2['bsetl'] =  res[25] 
     res2['bmet'] =  res[26] 
     res2['bmetc'] =  res[27] 
     res2['rs'] =  res[19] 
     res2['bs'] =  res[28] 
     res2['ben'] =  res[29]
     res2['benl'] =  res[30]
     res2['dq'] =  res[6]
     if (res2['renl'] === '3'){
      res2['renl'] =  '2'
    }
    if (res2['ren'] !== '0' && res2['renl'] === '0'){
      res2['renl'] =  '1'
    }
    if (res2['ben'] !== '0' && res2['benl'] === '0'){
      res2['benl'] =  '1'
    }
    
    if (res2['rsetl'] === '3'){
      res2['rsetl'] =  '2'
    }
    if (res2['rset'] !== '0' && res2['rsetl'] === '0'){
      res2['rsetl'] =  '1'
    }
    if (res2['rset'] !== '0' && res2['rsetc'] === '0'){
      res2['rsetc'] =  'D'
    }
    if (res2['bset'] !== '0' && res2['bsetc'] === '0'){
      res2['bsetc'] =  'D'
    }
   
    if (res2['bmet'] === 'PT' ){
      res2['bmetc'] =  'W'
    }
    if (res2['rmet'] === 'PT' ){
      res2['hmetc'] =  'W'
      res2['smetc'] =  'W'
    }
    
    if (window.currentSite === 'kay'){res2['store']= 'K'}
    if (window.currentSite === 'kay'  && res[21] !== 'K')
            { res[21]='K'
            //  res[24]='0'
            //  res[25]='0'
              res2['sku']= param2sku(res,5)[0]
            ////console.log('sku changed from ',in1,'to',res2['sku'])
            }
     if (window.currentSite === 'jared'){res2['store']= 'J'}
     if (window.currentSite === 'jared'  && res[21] !== 'J')
     { res[21]='J'
       res2['sku']= param2sku(res,6)[0]
    ////console.log('sku changed from ',in1,'to',res2['sku'])
     }
if (window.currentSite === 'zalesoutlet'){res2['store']= '2'}
if (window.currentSite === 'zalesoutlet'  && res[21] !== '2')
{ res[21]='2'
  res2['sku']= param2sku(res,7)[0]

}
if (window.currentSite === 'zales'){res2['store']= 'Z'}
if (window.currentSite === 'zales'  && res[21] !== 'Z')
{ res[21]='Z'
  res2['sku']= param2sku(res,7)[0]

}

if (window.currentSite === 'kayoutlet'){res2['store']= '1'}
if (window.currentSite === 'kayoutlet'  && res[21] !== '1')
{ res[21]='1'
  res2['sku']= param2sku(res,7)[0]

}
 //console.log('sku STORE ',res[21],res2['store'])
 
if (window.currentSite === 'peoplesjewellers'){res2['store']= '3'}
if (window.currentSite === 'peoplesjewellers'  && res[21] !== '3')
{ res[21]='3'
  res2['sku']= param2sku(res,7)[0]

}




//console.log('sku changed from ',in1,'to',res2['sku'])
  return res2   
  } 