//import getValueFromDictArray from "./utils"
//import {DEFAULT_STATE_VALUE,DEFAULT_STONE,ALL_STONES} from '../constants';
import {sku4band, sku4ring} from '../logic/sku-ver2';
import {DYNAMIC_OPTION_URL6} from '../constants';


export  default function imageLogic (state,view) {
  if (view === undefined)
    view=1
 var rand='' 
 ////console.log('start image logic',sku4ring(state['sku']),'---') 
 var base_url=state.view_image_base;
 if (state.imageversion === '6' )
 {
 base_url=DYNAMIC_OPTION_URL6
 console.log('start image logic',state) 
 rand='&random='+Math.random().toString()
 }

//if  (state.imageversion !== '6'){
 if (view === 4) return base_url+sku4ring(state['sku']) +'.jpg?v='+state['active_view4'].toString()+rand
 if (view === 5) return base_url+sku4band(state['sku']) +'.jpg?v='+state['active_view5'].toString()+rand
//}
 //if (window.currentEnv === 'test')
 // base_url = base_url.replace('images.','editor-images.')
 ////console.log('base_url',base_url)
   
    // add sku
   

 
    base_url+=state['sku'] +'.jpg?v='+view.toString()+rand
   // if (state['band']==='0')
           
 //}
  
////console.log('base_url',base_url)

 
  // add engraving
  // if (state.options_all !== undefined && state.options_all.indexOf('e') !== -1) {
   
  //   let not_typed = true
  //   if (state.options_e.show === true)
  //   for (let i in state.options_e.options) {
  //     if (state.options_e.options[i].show && state[state.options_e.options[i].attr] !== '' ) {
  //       not_typed = false
  //     }
      
  //   }
    

  //   let textValue;
  //  // if (state.options_e.show === true) {
  //  // cuff link  make double engraving
  //  let cuff_link_list=['6779','6781']
  //   for (let i in state.options_e.options) {
  //     if (not_typed)
  //       textValue =state.options_e.options[i].init_text;
  //     else
  //       textValue = state[state.options_e.options[i].attr]
  //     if (textValue !== '') {
  //       base_url+='&text' + i + '=' + textValue;
  //       if (cuff_link_list.indexOf(state['style']) !== -1) 
  //         base_url+='&text1=' + textValue;
  //     }  
  //     }
    
  //   if (state.hasOwnProperty('f')) {
  //     base_url+='&fontid=' +(parseInt(state['f'],10)%2).toString();
  //   }
  //}
 //}

  
//  base_url+='&material='+metalValue;
  ////console.log('imageLogic',base_url)


  //labels Front and Back

  

  return base_url
   
  }
  
