import React, { Component } from 'react';
import {Toolbar} from 'primereact/components/toolbar/Toolbar';
import {Button} from 'primereact/components/button/Button';
import {PRICE_URL,PRICE_URL11,PRICE_URL_PROD,VERSION} from '../constants';
import { FocusRing } from '@react-aria/focus';

class AiToolbar extends Component {
    constructor(props) {
       ////console.log('toolbar constr') 
        super(props);
    
        this.state = {
          price: null,
          lastSku: null,
          er: '',
          eb1: ''
        };
        this.componentDidUpdate()
      }
   
    componentDidUpdate() {
      //  console.log('toolbar did mount',this.state.lastSku,this.props.sku,  this.state.er,this.props.er,this.state.eb1,this.props.eb1) 
        var er = ''
        if (this.props.er.length > 0)
            er = 'x'
        var eb1 = ''    
        if (this.props.eb1.length > 0)
            eb1 = 'x'
        
        if (this.state.lastSku === this.props.sku && this.state.er === er && this.state.eb1 === eb1) return
        else {
            this.state.er = er
            this.state.eb1 = eb1
        }

  let fetchUrl =PRICE_URL
 // if (this.props.imageversion==='11')
 //   fetchUrl =PRICE_URL11
  
  if (window.currentEnv ==='www'){
      fetchUrl =PRICE_URL_PROD
  }
  let header = new Headers({
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'multipart/form-data'
        });
  let sentData = {
    method: 'GET', //opt.method,
    mode: 'cors',
    header: header,
  };
  //this.setState({ price: '0',lastSku: this.props.sku })
  this.state['lastSku'] = this.props.sku 
  //this.props.onRefreshReview('title','') //['refresh_parent'])
  //if (1===0)
  //console.log('new price', this.props.sku,this.props.extra)
  fetch(fetchUrl + this.props.sku + '?er=' + this.state.er + '&eb1=' + this.state.eb1 +'&src='+VERSION+'&extra='+this.props.extra , sentData)
      .then(response => {
          if (response.ok) {

              return response.json();
          }
          else {
        //      this.state={error: true}
              this.messages.show({
                  severity: 'error',
                  sticky: true,
                  summary: 'Error loading item info: ',
                  detail: 'Cannot load data'
              });
              throw new Error('Something went wrong ...');
          }
      })
      .then(json => {
        if (Object.keys(json).length === 0) {
          //    this.state={error: true}
              this.messages.show({
                  severity: 'error',
                  sticky: true,
                  summary: 'Error loading item info: ',
                  detail: 'No load data'
              });
              throw new Error('Something went wrong ...');
                }
                //this.setState ({ price: json['p'],lastSku: this.props.sku })
                this.state.price = json['p']
      //          this.state[ 'lastSku']= this.props.sku 
                this.props.onRefreshReview('price1',json.p.replace(',','')) //['refresh_parent'])
                if ('esa' in json){
                this.props.onRefreshReview('esa',json.esa) //['refresh_parent'])
                this.props.onRefreshReview('esadetail',json.esadetail)
                }
                this.props.onRefreshReview('refreshReview',json) //['refresh_parent'])
                this.props.onRefreshReview('title',json.refresh_parent.title) //['refresh_parent'])
               ////console.log('json.price3', this.state.price)
        })
  
      }
      
    
      
    
    render() {
        let cartText="Add to Shopping Bag" 
        let bcolor ='button3 ai-bcolor-' + window.currentSite
        ////console.log('toolbar render',this.state.lastSku,this.props.sku,this.state.price) 
        if (this.props.size === 'small') {
            cartText = "Add to Bag" 
        }
        let CC = ''
        if (this.props.costcard === 'cc') {
            CC = <Button label="CC" onClick={() => window.location = '//test.thepersonalizedbest.com/b2_costcard/?sku='+this.props.sku + '&er=' + this.state.er + '&eb1=' + this.state.eb1}/>
        }
        let AddButton=''
        if (this.props.readyForUserDiamond && this.props.readyForAddToCart && this.props.readyForSession){
            AddButton = <FocusRing focusRingClass="focus-ring"><button className={bcolor}  tabIndex="0" aria-label={cartText}   label={cartText} onClick={() => this.props.onAddToCart(this.props.urlParams,this.state.price)}>&nbsp;{cartText}&nbsp;</button></FocusRing>

            //AddButton = <Button className={bcolor}  label={cartText} onClick={() => this.props.onAddToCart(this.props.urlParams)}/>
        } else if (this.props.readyForUserDiamond === false) {
            AddButton = <FocusRing focusRingClass="focus-ring"><button className={bcolor}   tabIndex="0" aria-label={cartText} label={cartText} onClick={this.props.onNoUserDiamond}>&nbsp;{cartText}&nbsp;</button></FocusRing>

            //AddButton = <Button className={bcolor}  label={cartText} onClick={this.props.onNoUserDiamond}/>
        } else if (this.props.readyForSession === false) {
            AddButton = <FocusRing focusRingClass="focus-ring"><button className={bcolor}  role="button"  tabIndex="0" aria-label={cartText}  label={cartText} onClick={this.props.onNoSessionMessage}>&nbsp;{cartText}&nbsp;</button></FocusRing>

            //AddButton = <Button className={bcolor}  label={cartText} onClick={this.props.onNoSessionMessage}/>
        } else if (this.props.readyForAddToCart === false) {
            AddButton = <FocusRing focusRingClass="focus-ring"><button className={bcolor}  tabIndex="0" aria-label={cartText}  label={cartText} onClick={this.props.onNoOptionMessage}>&nbsp;{cartText}&nbsp;</button></FocusRing>


           ////console.log('activated AddB')
            //AddButton = <Button className={bcolor}  label={cartText} onClick={this.props.onNoOptionMessage}/>

        }
       ////console.log("ADD TO CART TEXT",this.props.readyForSession,this.props.readyForAddToCart)
       let color ='ai-toolbar-price ai-color-' + window.currentSite

       let price = this.state.price === null ? '' : ('$' + this.state.price)
    
        return (<div className="ai-toolbar-group">
        <Toolbar>
        
    <div className="ui-toolbar-group-left">
    <div className={color}>
    {price}
    </div>
    {CC}
    </div>
        
    <div className="ui-toolbar-group-right">
        {AddButton}
    </div>
</Toolbar>



      </div>
        );
    }
}

export default AiToolbar;