import { connect } from 'react-redux'
import { state_update } from '../actions'
import AiToolbar from './AiToolbar'
import { scrollToOption } from './AiOptionSet';
  
import ReactGA from 'react-ga4';
import {VERSION, ADD_TO_CART_URL,MSG_NO_USER_DIAMOND, ADD_TO_CART_URL_PROD,MSG_NOT_READY_ADD_TO_CART,DEFAULT_STATE_VALUE,MSG_NO_SESSION1,MSG_NO_SESSION2} from '../constants'; 

const showMessage =(dispatch,data)=>{
  dispatch(state_update({attr:'message',value:data}))
}
const addToCartOptions =(state)=>{
  ////console.log('check',state)
  var all_addcart_options =[]
 let general_options =['action','hc','hu','hs','he','sku','style','er','eb1','isposterminal','dct','dc','dcl','dl','dw','dd','initzalessku','esaselected']
  for (let new_opt in general_options) {
     all_addcart_options.push(general_options[new_opt])
  }

  let res={}
  res['site']= window.currentSite
  res['env']= window.currentEnv
  res['version']= VERSION

  if (state['cfg'] !== undefined){
    res['cfg']= state['cfg'].substring(9)
  }
  else 
    res['cfg']= ''
  let port = window.location.port
  ////console.log('PORT',port)  
  
  if (port !== '80' && port !== '' ) {
    port = ':' + port
  }
  else {
    port =''
  }

  res['shopping_url']=window.location.hostname +port
 // console.log(state)
  res['price']=state['price']
 for (let j in all_addcart_options) {
      res[all_addcart_options[j]]= state[all_addcart_options[j]] 
    }

  return res
}
const checkSession =(state)=>{
  if (state['hc'] === DEFAULT_STATE_VALUE || state['hc'] === DEFAULT_STATE_VALUE || state['hc'] === DEFAULT_STATE_VALUE  )
    return false
 // //console.log('session true')  
  return true  
  
}

const checkOptions =(state)=>{
 // //console.log('checkOptions RS',state.rs)
      if ( state['rs'] === '0') { 
    //  //console.log('checkOptions false') 
      return false
    }
   ////console.log('checkOptions true')
  return true
}
const checkUserDiamond =(state)=>{
  ////console.log('checkUD',state['ud'])
  if ( state['ud'] === 'U') { 
  if (Number.isNaN(parseFloat(state['dct'])) || parseFloat(state['dct']) < 0.23 || parseFloat(state['dct']) > 3.11) {//console.log('UDcheck - dct',state['dct']);
  return false;}
  if (state['dc'] === '') 
    {//console.log('UDcheck - dc',state['dc']);
    
    return false;}
  if (state['dcl'] === '') {//console.log('UDcheck - dcl',state['dcl']);
  return false;}
  if (Number.isNaN(parseFloat(state['dl'])) || parseFloat(state['dl']) < 3 || parseFloat(state['dl']) > 12) {//console.log('UDcheck - dl',state['dl'],parseFloat(state['dl']));
  return false;}
  if (Number.isNaN(parseFloat(state['dw'])) || parseFloat(state['dw']) < 3 || parseFloat(state['dw']) > 12){//console.log('UDcheck - dw',state['dw']);
  return false;}
  if (Number.isNaN(parseFloat(state['dd'])) || parseFloat(state['dd']) < 3 || parseFloat(state['dd']) > 12) {//console.log('UDcheck - dd',state['dd']);
  return false;}
  
  return true 
    }
  return true
}

const addToCart =(dispatch,param,price)=>{
  ////console.log('start add to cart',param)
  if (window.add2cart==='sent'  )
    return
  window.add2cart='sent'  

/*ReactGA.event({
  category: 'AddToCart',
  action:'AddToCart' 
});*/
  let header = new Headers({
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'multipart/form-data'
  });
  let sentData = {
      method: 'POST', 
      mode: 'cors',
      header: header,
      body: JSON.stringify(param)
      
  };
  let fetchUrl =ADD_TO_CART_URL
  

  if (window.currentEnv ==='www' ||window.currentEnv ==='prod2' ){
    fetchUrl =ADD_TO_CART_URL_PROD
  }
  //console.log('add to cart refresh',param,price)
  let sku =param.sku
    let skuPrice = price
    let nexusSite = window.currentSite
    let nexusSiteTitle = nexusSite.substring(0,1).toUpperCase() + nexusSite.slice(1)
    // 2000 for Kay, 2032 for KayOutlet, 2001 for Jared, 0008 for ZalesOutlet
    //let stext1 = 'window.adobeDataLayer.push({'+
    var dataLayer = new Object();dataLayer = {"cart": {"cartID": param.hc},
      "event":"cartItemAddition",
  "product": {"productListItems":[{"baseProduct":"V-" + sku ,"brand":nexusSite,  "description":"","inventoryLevel":"","origPrice":skuPrice,"outOfStock":"","parentProduct":"","personalization":"",
  "productCategory":"Custom Bridal","productClass":"","productName":"Bridal Customizer","productSKU": sku,"quantity":"1","rating": "","ratingCount":"","salePrice":skuPrice,"startingPrice":skuPrice,
  "title":nexusSiteTitle	}]},
  "user": {"asmLoggedIn":"false","city":"","country":"","email":"anonymous","guestID":"","lastLoginDate":"","loginMethod":"","loginSuccess":"","loyaltyID":"","loyaltyMember":"false","scid":"",
  "sessionID":param.hu,"state":"","userId":"anonymous","userStatus":"G","zipcode":""}};
  //console.log('push start',window.adobeDataLayer,dataLayer)
  try{
  if (window.adobeDataLayer !== null && window.adobeDataLayer !== undefined)
  {
   window.adobeDataLayer.push(dataLayer);
   //window.adobeDataLayer[0]=dataLayer;
   console.log('push done',window.adobeDataLayer)
  }
}
catch(e) {
  console.log('error in push',e)
  
}

  
  showMessage(dispatch,{severity: 'info', summary: '', sticky: false, detail: 'Redirecting to Shopping Bag'})
  /*ReactGA.event({
    category: 'test'+ window.testAB +'-add2cart',
    action:"addAB"
});*/
  fetch(fetchUrl+param.site+'/', sentData)
      .then(response => {

        window.add2cart='received'  
          if (response.ok) {
                return response.json();
              
          }
          else {
            showMessage(dispatch,{severity: 'error', summary: 'Error for Add to Cart: ', detail: 'No response'})
            
          }
      })
      .then(json => {

          if (Object.keys(json).length === 0) {
            showMessage(dispatch,{severity: 'error', summary: 'Error for Add to Cart: ', detail: 'Zero Size'})
          }
          ////console.log('load json', json)]
          var json2;
          if (param['action'] === 'param') {  
            json2 = JSON.stringify(json);
            showMessage(dispatch,{severity: 'info', summary: 'Action: ' + param['action'], sticky: true, detail: json2})
            return
          } else if (param['action'] === 'request') {  
            json2 = JSON.stringify(json);
            showMessage(dispatch,{severity: 'info', summary: 'Action: ' + param['action'], sticky: true, detail: json2})
            return
        } else if (param['action'] === 'response') {  
          json2 = JSON.stringify(json);
          showMessage(dispatch,{severity: 'info', summary: 'Action: ' + param['action'], sticky: true, detail: json2})
          return
        } else {  //redirect
 //         //console.log('redirect',json)
          if (json.code ==='0') {
            window.location = json.url
          }
          else {  
            if (json.msg.includes('error.cart.limit'))
             showMessage(dispatch,{severity: 'info', summary: 'Our Shopping Bags will only hold 50 items at a time. If ordering over 50 items, please place a second order for the additional items. Thank you for your understanding.' , sticky: false, detail: ''})
            else 
             showMessage(dispatch,{severity: 'info', summary: 'Action code is ' + json.code, sticky: false, detail: json.msg})
            return
          }
        }


          

          
      })
      .catch(errorMsg => {
          //error = true
          window.add2cart='received'  
          showMessage(dispatch,{severity: 'error',summary: 'Error for Add to Cart: ',detail: 'General Error'})
          
      //    //console.log('error', errorMsg)
      });
  }
 
const mapStateToProps = (state, ownProps) => ({
  sku: state['sku'],
  er: state['er'],
  extra:'_LAST'+state.last+'_SKU'+state.prevsku+'_CFG'+state.cfg,
  
  eb1: state['eb1'],
  readyForAddToCart: checkOptions(state),
 // cur_dc: state['dc'],
  readyForSession: checkSession(state),
  readyForUserDiamond: checkUserDiamond(state),
  urlParams: addToCartOptions(state),
  costcard:state['action'],
  imageversion: state.imageversion
})





const mapDispatchToProps = (dispatch, ownProps) => ({
  onRefreshReview: ( (attr,value) => dispatch(state_update({attr:attr,value:value}))),
  onNoOptionMessage: ( () => {dispatch(state_update({attr:'message',
    value:{severity: 'info',summary: 'Warning: ',detail: MSG_NOT_READY_ADD_TO_CART}}));
    setTimeout(function(){scrollToOption('rs')}, 50);}),
  onNoSessionMessage: ( () => {dispatch(state_update({attr:'message',
    value:{severity: 'error',summary: 'Warning: ',detail: MSG_NO_SESSION1 + window.currentEnv + '.'+ window.currentSite+'.com' +MSG_NO_SESSION2}}))}),
  onNoUserDiamond: ( (cur_dc) => {//console.log('active User Diamond');
  dispatch(state_update({attr:'message',value:{severity: 'error',summary: 'Warning: ',detail: MSG_NO_USER_DIAMOND}}))
  dispatch(state_update({attr:'valid-dc',value:false}))
  dispatch(state_update({attr:'valid-dcl',value:false}))
  dispatch(state_update({attr:'valid-dct',value:false}))
  dispatch(state_update({attr:'valid-dd',value:false}))
  dispatch(state_update({attr:'valid-dl',value:false}))
  dispatch(state_update({attr:'valid-dw',value:false}))

}),
  onAddToCart: ( (param,price) => addToCart(dispatch,param,price)  )
})



export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AiToolbar)