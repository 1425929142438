
import {
    includeRule,
    excludeRule
  }
  from './rules_functions.js';


 
  

  
export function rules_head(newState, state) {
    var newrule = includeRule(newState, state, 'head')
    newrule = excludeRule(newState, state, [
        ['ce', ['D','LD','UD']],
        ['dctw', ['1.5', '2','2.5','3']],
        ['head', ['H2']]], newrule)
        
    newrule = excludeRule(newState, state, [
        ['ce', ['C']],
        ['dctw', ['C3', 'C4']],
        ['head', ['H2']]], newrule)
    
    newrule = excludeRule(newState, state, [
        ['ce', ['D','LD','UD']],
        ['ds', ['PR','M']],
        ['head', ['T1TSP','T1TSPR','T1TSR','T1TSTR']]], newrule)
    
    newrule = excludeRule(newState, state, [
        ['ce', ['C']],
        ['head', ['T1TSP','T1TSPR','T1TSR','T1TSTR']]], newrule)

    newrule = excludeRule(newState, state, [
            ['ce', ['D','LD','UD']],
            ['dctw', ['2','2.5','3']],
            ['head', ['T1TSP','T1TSPR','T1TSR','T1TSTR']]], newrule)
        
        //-------------------------------------------------------------------------------------------------------------------------------
    newrule = excludeRule(newState, state, [
            ['ce', ['D','LD','UD']],
            ['dctw', ['1.5']],
            ['head', ['T1TSPR']]], newrule)
    newrule = excludeRule(newState, state, [
                ['ce', ['LD']],
                ['head', ['B1']]], newrule)
    
   
    newrule = excludeRule(newState, state, [
                    ['ce', ['D','LD','UD']],
                    ['ds', ['P']],
                    ['dctw', ['1.5']],
                    ['head', ['T1TSTR']]], newrule)
       //-------------------------------------------------------------------------------------------------------------------------------
      
    newrule = excludeRule(newState, state, [
        ['ce', ['D','LD','UD']],
        ['ds', ['R']],
        ['head', ['T1TSP','T1TSTR']]], newrule)
    newrule = excludeRule(newState, state, [
            ['ce', ['D','LD','UD']],
            ['ds', ['O']],
            ['head', ['T1TSP','T1TSTR']]], newrule)
    newrule = excludeRule(newState, state, [
                ['ce', ['D','LD','UD']],
                ['ds', ['O']],
                ['dctw', ['1.5']],
                ['head', ['T1TSR']]], newrule)
    newrule = excludeRule(newState, state, [
                ['ce', ['D','LD','UD']],
                ['ds', ['C']],
                ['head', ['T1TSP','T1TSTR']]], newrule)
    newrule = excludeRule(newState, state, [
        ['ce', ['D','LD','UD']],
        ['ds', ['P']],
        ['head', ['T1TSPR','T1TSR']]], newrule)
    newrule = excludeRule(newState, state, [
        ['ce', ['D','LD','UD']],
        ['ds', ['E']],
        ['head', ['T1TSP','T1TSPR','T1TSR']]], newrule)
    
        
        
    //------------------------------------------------------------------------------------------------------------------
    
    newrule['show']  = true
      return newrule
}